
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("探玩石列表", ["探玩石管理;add_point_records"])
    })

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const tableData = ref([])

    const formData = ref({
      nickname: '',
      mobile: ''
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    })

    let date = new Date()
    let from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    let to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)

    const duration = ref([
      from,
      to,
    ])

    const query = () => {
      store
        .dispatch(Actions.GET_ADD_POINT_RECORDS, {
          ...formData.value,
          ...page.value,
          from: `${duration.value[0].toLocaleDateString()} ${duration.value[0].getHours()}:${duration.value[0].getMinutes()}:${duration.value[0].getSeconds()}`,
          to: `${duration.value[1].toLocaleDateString()} ${duration.value[1].getHours()}:${duration.value[1].getMinutes()}:${duration.value[1].getSeconds()}`,
        })
        .then(() => {
          tableData.value = store.getters.currentAddPointRecords
          page.value.totalResult = store.getters.currentAddPointRecordCount
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    query()

    const submitEvent = () => {
      query()
    }

    const newAddPointRecords = () => {
      router.push({ name: "add_point_record_new" })
    }

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage
      query()
    }

    const onCancel = () => {
      formData.value.nickname = ''
      formData.value.mobile = ''
    }

    return {
      tableData,
      formData,
      submitEvent,
      newAddPointRecords,
      page,
      onPageChange,
      onCancel,
      duration
    }
  },
})
